import React from 'react'
import PropTypes from 'prop-types'
import Features from '../components/Features'
import {graphql, Link} from 'gatsby'

import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import GatsbySlickSlider from '../components/GatsbySlickSlider'

export const IndexPageTemplate = ({
  heading,
  youtube,
  description,
  intro,
  carousel
}) =>
    (
        <div>
            <GatsbySlickSlider data={carousel.slides}/>
            <section className="section section--gradient" style={{marginTop: "-3rem"}}>
                <div className="container">
                    <div className="section">
                        <div className="columns">
                            <div className="column is-10 is-offset-1">
                                <div className="content">
                                    <div className="columns">
                                        <div className="column is-half">
                                            <ContactForm/>
                                        </div>
                                        <div className="column is-half">
                                            <h3 className="has-text-weight-semibold is-size-2">
                                                {youtube.heading}
                                            </h3>
                                            <div className="iframeVideo">
                                                <iframe title={youtube.title}
                                                    src={'https://www.youtube.com/embed/' + youtube.id} frameBorder="0"
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="has-text-weight-semibold has-text-centered is-size-2">
                                        {heading}
                                    </h3>
                                    <p className="has-text-centered">{description}</p>
                                    <ul className="linkList has-text-weight-semibold is-size-2">
                                        <li>
                                            <Link to="/weightloss">
                                                Weight loss
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/quit-smoking">
                                                Quit Smoking
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/stress-management">
                                                Stress Management
                                            </Link>
                                        </li>
                                        <li><Link to="/unwanted-habits">
                                            Unwanted Habits
                                        </Link></li>
                                        <li><p>Other programs available upon request.</p></li>
                                    </ul>
                                    <div className="has-text-centered has-text-weight-semibold is-size-3">
                                        <p>To know if this is right for you</p>
                                        <p>Call Now For Your <strong>Free</strong> Hypnotic Screening</p>
                                        <p><a href="tel:289-440-6762">289-440-6762</a></p>
                                    </div>
                                    <Features gridItems={intro.blurbs}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

IndexPageTemplate.propTypes = {
    carousel: PropTypes.shape({
        slides: PropTypes.array,
    }),
    youtube: PropTypes.shape(({
        heading: PropTypes.string,
        title: PropTypes.string,
        id: PropTypes.string
    })),
    intro: PropTypes.shape({
        blurbs: PropTypes.array,
    }),
    heading: PropTypes.string,
    description: PropTypes.string
}

const IndexPage = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout>
            <IndexPageTemplate
                carousel={frontmatter.carousel}
                youtube={frontmatter.youtube}
                heading={frontmatter.heading}
                intro={frontmatter.intro}
            />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heading
        youtube {
          heading
          title
          id
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
        }
        carousel {
          slides {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
            subtext
          }
        }
      }
    }
  }
`
