import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const GatsbySlickSlider = class SimpleSlider extends React.Component {
    render() {
        const settings = {
            autoplay: true,
            arrows: true,
            autoplaySpeed: 7000,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <Slider {...settings} className="slick-custom">
                {
                    this.props.data && this.props.data.map((slide, index) =>
                        (
                            <div key={index}>
                                <div
                                    className="full-width-image fixed margin-top-0"
                                    style={{
                                        backgroundImage: `url(${
                                            !!slide.image.childImageSharp ? slide.image.childImageSharp.fluid.src : slide.image
                                        })`,
                                        backgroundPosition: `center`
                                    }}
                                >
                                    {slide.text && <div
                                        style={{
                                            display: 'flex',
                                            height: '150px',
                                            lineHeight: '1',
                                            justifyContent: 'space-around',
                                            alignItems: 'left',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <h1
                                            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                                            style={{
                                                boxShadow: '0.5rem 0 0 #4E9836, -0.5rem 0 0 #4E9836',
                                                backgroundColor: '#4E9836',
                                                color: 'white',
                                                lineHeight: '1',
                                                padding: '0.25em',
                                            }}
                                        >
                                            {slide.text}
                                        </h1>

                                        {slide.subtext && <h3
                                            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                                            style={{
                                                boxShadow:
                                                    'rgb(78, 152, 54) 0.5rem 0px 0px, rgb(78, 152, 54) -0.5rem 0px 0px',
                                                backgroundColor: 'rgb(78, 152, 54)',
                                                color: 'white',
                                                lineHeight: '1',
                                                padding: '0.25em',
                                            }}
                                        >
                                            {slide.subtext}
                                        </h3>}
                                    </div>}
                                </div>
                            </div>
                        ))
                }
            </Slider>
        );
    }
}

export default GatsbySlickSlider;